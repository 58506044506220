import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
      path: '/',
      name: 'index',
      redirect: "/logon"
  },
  // 登录
  {
    path: '/logon',
    name: 'logon',
    component: () => import('../views/logon.vue')
  },
  // 设置
  {
    path: '/setup',
    name: 'setup',
    component: () => import('../views/setup.vue')
  },
  // 编辑个人资料
  {
    path: '/mine/myinfo',
    name: 'myinfo',
    component: () => import('../views/myinfo.vue')
  },
  // 修改密码
  {
    path: '/revisepassword',
    name: 'revisepassword',
    component: () => import('../views/revisepassword.vue')
  },
  //我的消费记录
  {
    path: '/mine/mycostrecord',
    name: 'mycostrecord',
    component: () => import('../views/mine/mycostrecord.vue')
  },
  // 我的抵用券
  {
    path: '/mine/mycertificate',
    name: 'mycertificate',
    component: () => import('../views/mine/mycertificate.vue')
  },
  // 会员中心
  {
    path: '/mine/card/:cardNo',
    name: 'card',
    component: () => import('../views/mine/card.vue')
  },
  // 我的二维码
  {
    path: '/mine/mycode/:cardNo',
    name: 'mycode',
    component: () => import('../views/mine/mycode.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
