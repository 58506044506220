import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
import store from './vuex/store'
import axios from 'axios'
import Vuex from 'vuex'
import ElementUI from 'element-ui'
import Mint from 'mint-ui';
import Vant from 'vant-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Clipboard from 'clipboard'
import { Toast } from 'vant';
import { Notify } from 'vant';
import { Area } from 'vant';


import 'mint-ui/lib/style.css';

import { Spinner } from 'mint-ui';
 
import { Loadmore } from 'mint-ui';
import { Button } from 'mint-ui';



Vue.config.productionTip = false
Vue.prototype.$http = axios
// Vue.use(ElementUI)
Vue.use(Notify)
Vue.use(Vuex)
Vue.use(Mint)
Vue.use(Vant)
Vue.use(Area);
Vue.component(Loadmore.name, Loadmore);
Vue.component(Spinner.name, Spinner);
Vue.component(Button.name, Button);



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
