import Vue from 'vue'
import Vuex from 'vuex'
// import * as actions from './actions'
// import * as getters from './getters'
// import user from './user.store'
// import router from './router.store'
import dropDown from './module/dropDown'


Vue.use(Vuex)

// 应用初始状态
const state = {

}

// 定义所需的 mutations
const mutations = {
}

// 创建 store 实例
export default new Vuex.Store({
    // actions,
    // getters,
    state,
    mutations,
    modules: {
        dropDown
    }
})


Vue.use(Vuex)